<template>
  <div>
    <div class="container">
      <section>
        <div class="section-heading">
          <h1>Meet our team</h1>
        </div>
      </section>
      <div id="team">
        <h2>Management</h2>
        <div class="team-wrapper">
          <div @click="toggleTeamInfo(0)">
            <img :src="require('@/assets/images/team/crop/01.jpg')" alt>
          </div>
          <div @click="toggleTeamInfo(1)">
            <img :src="require('@/assets/images/team/crop/02.jpg')" alt>
          </div>
          <div @click="toggleTeamInfo(2)">
            <img :src="require('@/assets/images/team/crop/03.jpg')" alt>
          </div>
          <div @click="toggleTeamInfo(3)">
            <img :src="require('@/assets/images/team/crop/04.jpg')" alt>
          </div>
          <article
            class="team-info"
            v-for="value, index in teamData"
            v-if="teamInfoNo == index && teamInfoNo <= 3"
            :key="index"
          >
            <div class="container">
              <div class="close" @click="teamInfoNo = null"></div>
              <div class="team-info-content">
                <h2>{{ value.name }}</h2>
                <h3>{{ value.position }}</h3>
                <p v-if="value.profile" v-html="value.profile"></p>
              </div>
              <div class="team-info-image">
                <img :src="require(`@/assets/images/team/${value.imageUrl}.jpg`)" alt>
              </div>
            </div>
          </article>
        </div>
        <hr>
        <h2>Linguist</h2>
        <div class="team-wrapper">
          <div @click="toggleTeamInfo(4)">
            <img :src="require('@/assets/images/team/crop/05.jpg')" alt>
          </div>
          <div @click="toggleTeamInfo(5)">
            <img :src="require('@/assets/images/team/crop/06.jpg')" alt>
          </div>
          <div @click="toggleTeamInfo(6)">
            <img :src="require('@/assets/images/team/crop/07.jpg')" alt>
          </div>
          <div @click="toggleTeamInfo(7)">
            <img :src="require('@/assets/images/team/crop/08.jpg')" alt>
          </div>
          <article
            class="team-info"
            v-for="value, index in teamData"
            v-if="teamInfoNo == index && teamInfoNo > 3 && teamInfoNo <= 7"
            :key="index"
          >
            <div class="container" v-if="value.profile">
              <div class="close" @click="teamInfoNo = null"></div>
              <div class="team-info-content">
                <h2>{{ value.name }}</h2>
                <h3 v-if="value.position">{{ value.position }}</h3>
                <p v-if="value.profile" v-html="value.profile"></p>
              </div>
              <div class="team-info-image">
                <img :src="require(`@/assets/images/team/${value.imageUrl}.jpg`)" alt>
              </div>
            </div>
          </article>
          <div @click="toggleTeamInfo(8)">
            <img :src="require('@/assets/images/team/crop/09.jpg')" alt>
          </div>
          <div @click="toggleTeamInfo(9)">
            <img :src="require('@/assets/images/team/crop/10.jpg')" alt>
          </div>
          <div @click="toggleTeamInfo(10)">
            <img :src="require('@/assets/images/team/crop/11.jpg')" alt>
          </div>
          <div @click="toggleTeamInfo(11)">
            <img :src="require('@/assets/images/team/crop/12.jpg')" alt>
          </div>
          <article
            class="team-info"
            v-for="value, index in teamData"
            v-if="teamInfoNo == index && teamInfoNo > 7 && teamInfoNo <= 11"
            :key="index"
          >
            <div class="container">
              <div class="close" @click="teamInfoNo = null"></div>
              <div class="team-info-content">
                <h2>{{ value.name }}</h2>
                <h3 v-if="value.position">{{ value.position }}</h3>
                <p v-if="value.profile" v-html="value.profile"></p>
              </div>
              <div class="team-info-image">
                <img :src="require(`@/assets/images/team/${value.imageUrl}.jpg`)" alt>
              </div>
            </div>
          </article>
          <div @click="toggleTeamInfo(12)">
            <img :src="require('@/assets/images/team/crop/13.jpg')" alt>
          </div>
          <div @click="toggleTeamInfo(13)">
            <img :src="require('@/assets/images/team/crop/14.jpg')" alt>
          </div>
          <div @click="toggleTeamInfo(14)">
            <img :src="require('@/assets/images/team/crop/15.jpg')" alt>
          </div>
          <article
            class="team-info"
            v-for="value, index in teamData"
            v-if="teamInfoNo == index && teamInfoNo > 11"
            :key="index"
          >
            <div class="container">
              <div class="close" @click="teamInfoNo = null"></div>
              <div class="team-info-content">
                <h2>{{ value.name }}</h2>
                <h3 v-if="value.position">{{ value.position }}</h3>
                <p v-if="value.profile" v-html="value.profile"></p>
              </div>
              <div class="team-info-image">
                <img :src="require(`@/assets/images/team/${value.imageUrl}.jpg`)" alt>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.section-heading {
  margin-bottom: 0;
}

#team {
  > h2 {
    text-align: center;
    margin-bottom: 30px;
    color: $secondary-grey;
  }

  hr {
    margin-bottom: 60px;
  }
}

.team-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transition: 0.4s ease-in-out;
  margin-left: -20px;
  margin-right: -20px;

  > div {
    flex: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    transition: 0.4s ease-in-out;

    @media screen and (max-width: 600px) {
      padding-left: 10px;
      padding-right: 10px;
      margin: 20px 0;
    }

    @media screen and (max-width: 450px) {
      padding-left: 5px;
      padding-right: 5px;
      margin: 10px 0;
    }

    &:hover {
      cursor: pointer;
      transform: translate3d(0, -10px, 0);
      transition: 0.4s ease-in-out;

      @media screen and (max-width: 450px) {
        transform: translate3d(0, -5px, 0);
      }
    }

    &:hover > img {
      box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15);
      transition: 0.4s ease-in-out;

      @media screen and (max-width: 450px) {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
      }
    }

    &:last-of-type {
      margin-bottom: 60px;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 100%;
      transition: 0.4s ease-in-out;

      &:hover {
        opacity: 1;
        cursor: pointer;
        transition: 0.4s ease-in-out;
      }
    }
  }
}

.team-info {
  min-width: calc(100vw - 20px);
  flex-basis: 100%;
  background: #f7f7f7;
  // border-top: 2px solid $border-grey;
  // border-bottom: 2px solid $border-grey;
  visibility: visible;

  .close {
    position: relative;
    flex-basis: 100%;
    margin-bottom: 40px;
    margin-top: 40px;
    top: initial;
    right: initial;
    bottom: initial;
    left: initial;
    opacity: 0.5;
    transition: 0.4s ease-in-out;

    &:hover {
      opacity: 1;
      transition: 0.4s ease-in-out;
    }

    &:before {
      left: initial;
      right: 15px;
    }

    &:after {
      left: initial;
      right: 15px;
    }
  }

  .container {
    display: flex;
    max-width: 970px;
    flex-wrap: wrap;
  }

  div {
    flex: 1;
    flex-basis: 50%;
    align-items: center;
  }

  @media screen and (max-width: 700px){
    div{
      flex-basis: 100%;
    }
  }

  .team-info-content {
    padding-top: 90px;
    padding-bottom: 90px;
    padding-right: 50px;

    h2 {
      margin-bottom: 10px;
    }

    h3 {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 700px) {
    .team-info-content {
      padding-top: 0;
      padding-bottom: 20px;
      padding-right: 0;

      h2 {
        margin-bottom: 10px;
      }

      h3 {
        margin-bottom: 20px;
      }
    }
  }

  .team-info-image {
    line-height: 0;

    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>
<script>
import teamData from "@/assets/json/team.json";

export default {
  metaInfo: {
    title: "Wallace Solutions",
    titleTemplate: "%s | Services"
  },
  data() {
    return {
      teamInfoNo: null,
      teamData: teamData
    };
  },
  methods: {
    toggleTeamInfo: function(teamInfoNo) {
      if (this.teamInfoNo === null || this.teamInfoNo !== teamInfoNo) {
        this.teamInfoNo = teamInfoNo;
      } else {
        this.teamInfoNo = null;
      }
    }
  },
  mounted() {
    // console.log(this.teamData)
  }
};
</script>
